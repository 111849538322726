import { cn } from '@bem-react/classname';
import React from 'react';
import { ContentFlow, Horisontal, ScreenSize } from '../Flow';
import { PromoLink } from '../Link/Landing';
import Section, { SECTION_COLOR } from '../Section/Landing';
import './footer.scss';

const Footer = () => {
    const footer = cn('Footer');
    return <footer className={footer()}>
        <Section color={SECTION_COLOR.Black}>
            <Horisontal centerred spacedEvenly>
                <a href="https://www.facebook.com/ViewstAds/" className={footer('SN-Icon', {'facebook': true})}></a>
                <a href="https://twitter.com/ViewstBuilder" className={footer('SN-Icon', {'twitter': true})}></a>
                <a href="https://www.youtube.com/channel/UC2wKpRTFZ01VYQfhE5jsDdA" className={footer('SN-Icon', {'youtube': true})}></a>
                <a href="https://www.linkedin.com/company/viewstadsbuilder/" className={footer('SN-Icon', {'linkedin': true})}></a>
            </Horisontal>
        </Section>
        <Section centered color={SECTION_COLOR.Black}>
            <ContentFlow centerred horisontal={[ScreenSize.L, ScreenSize.M]} vertical={[ScreenSize.S]}>
                <PromoLink medium href="https://viewst.com/terms-and-conditionals/">Terms and conditions</PromoLink>
                <PromoLink medium href="https://viewst.com/brand-safety-policy/">Brand safety</PromoLink>
                <PromoLink medium href="https://viewst.com/privacy-policy/">Privacy policy</PromoLink>
                <PromoLink medium href="https://viewst.com">© Viewst 2021</PromoLink>
            </ContentFlow>
        </Section>
    </footer>
}

export default Footer;
