import { cn } from '@bem-react/classname';
import React from 'react';
import './screen.scss';

const classItem = cn('Screen');

const ScreenL = ({children, andBelow=false, andAbove=false}) => {
    return <div className={classItem('L', {andBelow, andAbove})}>{children}</div>
}

const ScreenM = ({children, andBelow=false, andAbove=false}) => {
    return <div className={classItem('M', {andBelow, andAbove})}>{children}</div>
}
const ScreenS = ({children, andBelow=false, andAbove=false}) => {
    return <div className={classItem('S', {andBelow, andAbove})}>{children}</div>
}
export {
    ScreenL,
    ScreenM,
    ScreenS,
};
