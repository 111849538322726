import { cn } from '@bem-react/classname';
import React from 'react';
import { Horisontal } from '../Flow';
import { PromoLink } from '../Link/Landing';
import Section, { SECTION_COLOR } from '../Section/Landing';
import './Landing.scss';


const Header = () => {
    const headerClass = cn('LandingHeader');
    const logoClass = cn('LandingLogo');

    return (
        <header className={headerClass()}>
            <Section centerred={false} color={SECTION_COLOR.DarkBlue} smallBottom>
                <Horisontal spaced>
                    <img alt="Viewst" className={logoClass()} height="100%" width="auto" src={'/images/logotype-white.png'} />
                    <PromoLink small buttonLike href="https://wizard.viewst.com/?lang=en" target="_blank" rel="noopener noreferrer">
                        Start designing
                    </PromoLink>
                </Horisontal>
            </Section>
        </header>
    )
}

export default Header;
