import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import { PixelsAndAnalytics } from "../components/PixelsAndAnalytics/PixelsAndAnalytics";
import ReviewsSlider from "../components/ReviewsSlider";
import { Horisontal, useMediaQuery, Vertical } from "../landingnewcomponents/Flow";
import Footer from "../landingnewcomponents/Footer/Landing";
import { GridList, GridListItem } from "../landingnewcomponents/GridList/Landing";
import Header from "../landingnewcomponents/Header/Landing";
import Image from "../landingnewcomponents/Image/Landing";
import { LandingLink, PromoLink } from "../landingnewcomponents/Link/Landing";
import Paragraph from "../landingnewcomponents/Paragraph/Landing";
import { ScreenL, ScreenM, ScreenS } from "../landingnewcomponents/Screen";
import Section, { SECTION_COLOR } from "../landingnewcomponents/Section/Landing";
import SubTitle from "../landingnewcomponents/SubTitle/Landing";
import Title, { TITLE_COLORS } from "../landingnewcomponents/Title/Landing";
import ContactUsSection from "../sections/contact-us";
import FAQSection from "../sections/faq";
import TutorialsSection from "../sections/tutorials";
import "./../common/styles.scss";
import messages from "./../data/messages/en";
import { graphql, useStaticQuery } from "gatsby";
import { normalizePost } from "../helpers/normalizePost";

const LandingPage = ({ pageContext }) => {
  const { templates, sections } = pageContext;
  const [withDots, setWithDots] = useState(false);

  useMediaQuery("(min-width: 0) and (max-width: 1023px)", (matches) => {
    setWithDots(matches);
  });

  const tutorialsPostsFromWordpressRaw = useStaticQuery(graphql`
    query {
        tutorials: allWpPost(    
            limit: 3
            filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 1}}}}}) {
            nodes {
              title
              link
              content
              databaseId
              date
              postsFields {
                featuredImage {
                  databaseId
                  mediaItemUrl
                }
              }
            }
        }
    }
  `);
  const tutorialsPostsFromWordpress = tutorialsPostsFromWordpressRaw.tutorials.nodes.map(normalizePost);

  return (
    <IntlProvider locale={"en"} messages={messages}>
      <Helmet
        htmlAttributes={{
          lang: "en"
        }}
      >
        <title>{sections.main.title}</title>
      </Helmet>
      <Header />
      <Section color={SECTION_COLOR.DarkBlue}>
        <ScreenS>
          <Title color={TITLE_COLORS.White}>{sections.main.title}</Title>
          <Image
            alt={sections.main.title}
            src={sections.main.picture} />
          <Paragraph>{sections.main.description}</Paragraph>
          <Vertical centerred style={{ marginBottom: "40px" }}>
            <PromoLink href={"https://wizard.viewst.com/signup?lang=en"} buttonLike>Start
              designing</PromoLink>
            <PromoLink href={"https://calendly.com/callvd"}>Book a demo</PromoLink>
          </Vertical>
        </ScreenS>
        <ScreenM andAbove>
          <Title color={TITLE_COLORS.White}>{sections.main.title}</Title>
          <Horisontal>
            <div>
              <Paragraph>{sections.main.description}</Paragraph>
              <ScreenM>
                <Vertical style={{ marginBottom: "80px" }}>
                  <PromoLink href={"https://wizard.viewst.com/signup?lang=en"} buttonLike>Start
                    designing</PromoLink>
                  <PromoLink href={"https://calendly.com/callvd"}>Book a demo</PromoLink>
                </Vertical>
              </ScreenM>
              <ScreenL>
                <Horisontal leftAligned>
                  <PromoLink href={"https://wizard.viewst.com/signup?lang=en"} buttonLike>Start
                    designing</PromoLink>
                  <PromoLink href={"https://calendly.com/callvd"}>Book a demo</PromoLink>
                </Horisontal>
              </ScreenL>
            </div>
            <Image
              alt={sections.main.title}
              src={sections.main.picture} />
          </Horisontal>
        </ScreenM>
      </Section>
      <Section color={SECTION_COLOR.LightBlue}>
        <ScreenM andAbove>
          <Title standout color={TITLE_COLORS.White}>{sections.design_section.title}</Title>
          <Horisontal>
            <Paragraph>{sections.design_section.content}</Paragraph>
            <Image
              style={{
                position: "relative",
                marginTop: "-180px"
              }}
              sSizeSrc="/images/landings/S/design_quickly_and_easily.png"
              mSizeSrc="/images/landings/M/design_quickly_and_easily.png"
              lSizeSrc="/images/landings/L/design_quickly_and_easily.png"
            />
          </Horisontal>
        </ScreenM>
        <ScreenS>
          <Title standout color={TITLE_COLORS.White}>{sections.design_section.title}</Title>
          <Paragraph>{sections.design_section.content}</Paragraph>
          <Image src="/images/landings/S/design_quickly_and_easily.png" />
        </ScreenS>
      </Section>
      <Section color={SECTION_COLOR.White}>
        <ScreenM andAbove>
          <Horisontal>
            <Vertical>
              <Title color={TITLE_COLORS.LightBlue}>
                <span dangerouslySetInnerHTML={{ __html: sections.collaborate_section.title }} />
              </Title>
              <Paragraph>{sections.collaborate_section.content}</Paragraph>
            </Vertical>
            <Image src="/images/landings/M/collaborate_with_teams.png" />
          </Horisontal>
        </ScreenM>
        <ScreenS>
          <Title color={TITLE_COLORS.LightBlue}>
            <span dangerouslySetInnerHTML={{ __html: sections.collaborate_section.title }} />
          </Title>
          <Paragraph>{sections.collaborate_section.content}</Paragraph>
          <Image src="/images/landings/S/collaborate_with_teams.png" />
        </ScreenS>
      </Section>
      <Section color={SECTION_COLOR.Violet}>
        <ScreenS>
          <Title color={TITLE_COLORS.White}>{sections.feed_section.title}</Title>
          <Paragraph>{sections.feed_section.content}</Paragraph>
          <Image
            sSizeSrc="/images/landings/S/feed_generation_1x@2x.png"
            mSizeSrc="/images/landings/M/feed_generation_1x@2x.png"
            lSizeSrc="/images/landings/L/feed_generation_1x@2x.png"
          />
        </ScreenS>
        <ScreenM andAbove>
          <Horisontal>
            <Image
              sSizeSrc="/images/landings/S/feed_generation_1x@2x.png"
              mSizeSrc="/images/landings/M/feed_generation_1x@2x.png"
              lSizeSrc="/images/landings/L/feed_generation_1x@2x.png"
            />
            <Vertical>
              <Title color={TITLE_COLORS.White}>Feed<br />generation</Title>
              <Paragraph>{sections.feed_section.content}</Paragraph>
            </Vertical>
          </Horisontal>
        </ScreenM>
      </Section>
      <Section color={SECTION_COLOR.White}>
        <Title color={TITLE_COLORS.Violet}>{sections.howTo.title}</Title>
        <SubTitle>{sections.howTo.description}</SubTitle>
        <GridList className="GridList_l7_m3_s2">
          {sections.howTo.content.map((text) => {
            return <GridListItem ordered key={text}>{text}</GridListItem>;
          })}
        </GridList>
      </Section>
      <Section color={SECTION_COLOR.Pink}>
        <Title standout>{sections.create_in_one_click_section.title}</Title>
        <ScreenS>
          <Paragraph>{sections.create_in_one_click_section.content}</Paragraph>
          <Image src="/images/landings/S/create_bundles_with_one_click@2x.png" />
        </ScreenS>
        <ScreenM>
          <Horisontal>
            <Image
              src="/images/landings/M/create_bundles_with_one_click@2x.png"
            />
            <Paragraph>{sections.create_in_one_click_section.content}</Paragraph>
          </Horisontal>
        </ScreenM>
        <ScreenL>
          <Paragraph>{sections.create_in_one_click_section.content}</Paragraph>
          <Image src="/images/landings/L/create_bundles_with_one_click@2x.png" />
        </ScreenL>
      </Section>
      <Section color={SECTION_COLOR.White}>
        <Title color={TITLE_COLORS.Pink}>{sections.animations_section.title}</Title>
        <Paragraph>{sections.animations_section.content}</Paragraph>
        <ScreenS>
          <Image
            sSizeSrc="/images/landings/S/animate_ads_instantly.png"
            mSizeSrc="/images/landings/M/animate_ads_instantly.png"
            lSizeSrc="/images/landings/L/animate_ads_instantly.png"
          />
        </ScreenS>
        <ScreenM andAbove>
          <Image
            sSizeSrc="/images/landings/S/animate_ads_instantly.png"
            mSizeSrc="/images/landings/M/animate_ads_instantly.png"
            lSizeSrc="/images/landings/L/animate_ads_instantly.png"

            style={{
              marginTop: "-80px",
              top: "-60px"
            }}
          />
        </ScreenM>
      </Section>
      <Section color={SECTION_COLOR.LightBlue}>
        <ScreenM andAbove>
          <Horisontal>
            <Vertical>
              <Title color={TITLE_COLORS.White}><span
                dangerouslySetInnerHTML={{ __html: sections.brand_kits_section.title }} /></Title>
              <Paragraph>{sections.brand_kits_section.content}</Paragraph>
            </Vertical>
            <Image src="/images/landings/M/keep_brand_kits_organized.png" />
          </Horisontal>
        </ScreenM>
        <ScreenS>
          <Title color={TITLE_COLORS.White}><span
            dangerouslySetInnerHTML={{ __html: sections.brand_kits_section.title }} /></Title>
          <Paragraph>{sections.brand_kits_section.content}</Paragraph>
          <Image src="/images/landings/S/keep_brand_kits_organized.png" />
        </ScreenS>
      </Section>
      <Section color={SECTION_COLOR.White}>
        <Horisontal>
          <Title color={TITLE_COLORS.DarkBlue}>Popular banner templates</Title>
          <LandingLink href="https://www.pinterest.ru/evgeniashalenko/_created/">See All</LandingLink>
        </Horisontal>
        <GridList className="GridList_l4_m3_s2" tile>
          <GridListItem><Image src="/images/landings/templates/template-1@2x.png" /></GridListItem>
          <GridListItem><Image src="/images/landings/templates/template-2@2x.png" /></GridListItem>
          <GridListItem><Image src="/images/landings/templates/template-3@2x.png" /></GridListItem>
          <GridListItem><Image src="/images/landings/templates/template-4@2x.png" /></GridListItem>
          <GridListItem><Image src="/images/landings/templates/template-5@2x.png" /></GridListItem>
          <GridListItem><Image src="/images/landings/templates/template-6@2x.png" /></GridListItem>
          <GridListItem><ScreenL andAbove><Image
            src="/images/landings/templates/template-7@2x.png" /></ScreenL></GridListItem>
          <GridListItem><ScreenL andAbove><Image
            src="/images/landings/templates/template-8@2x.png" /></ScreenL></GridListItem>
        </GridList>
      </Section>
      <Section centered custom="slider" color={SECTION_COLOR.DarkGray}>
        <Title color={TITLE_COLORS.White} centered>What our customers say</Title>
        <SubTitle centered>Join the community</SubTitle>
        <ReviewsSlider dots={withDots} />
      </Section>
      <FAQSection faq={sections.faq} />
      <TutorialsSection
        title="Tutorials"
        sectionColor={SECTION_COLOR.White}
        titleColor={TITLE_COLORS.LightBlue}
        seeAllUrl="https://viewst.com/tutorials"
        data={tutorialsPostsFromWordpress}
      />
      <ContactUsSection />
      <Footer />
      <link rel="stylesheet" href="/fonts/stylesheet.css" type="text/css" />
      <PixelsAndAnalytics />
    </IntlProvider>
  );
};

export default LandingPage;
